import React from 'react';
import GlobalLayout from '../components/GlobalLayout';

function Dashboard() {
  const propsData = {
    keys: ['index'],
  };
  return (
    <GlobalLayout data={propsData}>
      <h4>操作说明：</h4>
      <ol>
        <li>
          登录地址：https://console.qzwy.com/login，账号：admin，密码：chenyuan。
        </li>
        <li>
          阿里云OSS浏览器的下载、使用:<br />
          下载地址：http://gosspublic.alicdn.com/oss-browser/1.13.0/oss-browser-win32-x64.zip?spm=a2c4g.11186623.2.13.2f641144fXohws&file=oss-browser-win32-x64.zip<br />
          AccessKey ID：****，AccessKey Secret：****，单独存放，用于登录。<br />
          通过阿里云OSS浏览器可存储 Markdown 文章、图片资源等，每个资源会产生独立的URL，该URL可存放在数据库中。
        </li>
        <li>
          <p>如何获取图片、MarkDown 文章的 URL：</p>
          <p>
            说明：所有 URL 的前缀：https://public.qzwy.com
          </p>
          <p>
            举例：https://public.qzwy.com 替换 OSS 浏览器中的oss://qzwy/static/images/office/banner/banner-bg1.jpg中的oss://qzwy得到：https://public.qzwy.com/static/images/office/banner/banner-bg1.jpg，即是我们所需要的 URL 。
          </p>          
        </li>
        <li>
          每个案例的项目ID由6位数字组成，第一位数字表示类型，1表示办公园区，2表示展示商业。后面5位数字区分不同项目，数字越大表示最近的项目，排在列表前列。项目数字最好有个间隔，方便调整，如100001,100003,100005...,
        </li>
        <li>
          知识堂文章的 ID 号，也是顺序之分，用7位数字表示，前两位表示类别，11-99，后5位表示该类别下的不同文章。
        </li>
        <li>
          <h4>首页轮播图第一张图片更换：</h4>
          <p>oss://qzwy/static/images/index/1440x480-index-1.jpg，在阿里云 OSS 中，替换这张图片为新的图片。注意：文件名、图片规格保持不变。</p>
        </li>
        <li>
        </li>
      </ol>
    </GlobalLayout>
  )
}

export default Dashboard;
