import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {
  List,
  Card,
  Badge,
  Modal,
  Button,
  Input,
} from 'antd';

import { FilePdfTwoTone, FilePdfOutlined } from '@ant-design/icons';
import apiUrl from '../../config/apiUrl';

const { Meta } = Card;

const CaseList = props => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ summary, setSummary ] = useState('');
  const [ fileList, setFileList ] = useState([]);
  const { caseId, leibie, data } = props;

  const [biaohao, setBiaohao] = useState(0);

  const [wenjianjia, setWenjianjia] = useState([]);

  const [hetongwenjianjihemingcheng, setHetongwenjianjihemingcheng] = useState('');
  const [wenjianListOne, setWenjianListOne] = useState({title: 'a1', path: 'b1'});

  useEffect( () => {
    getList(caseId);
  }, []);

  const getList = async (caseId) => {
    let dataProps = {params: { caseId }};
    const res = await axios.get(apiUrl.getCaseList, dataProps);
    let data = res.data.data;
    console.log('data-->getCaseList--> : ', data );
    const leibies = ['合同', '工程文件', '签证、改签文件', '保修、维修文件']
    console.log('data[0].ziliao: ', data[0].ziliao);
    const index = data[0].ziliao.findIndex( x => x.leibie === leibie );
    const list = data[0].ziliao[index].wenjianjihe;
    console.log('list: ', list);
    setWenjianjia(list);
  };

  const showModal = (summary, biaohao, fileList) => {
    setBiaohao(biaohao);
    setSummary(summary);
    setFileList(fileList);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    console.log('添加文件', caseId);
    let dataProps = {};
    dataProps.caseId = caseId;
    dataProps.leibie = leibie;
    let file = wenjianListOne;

    console.log('updateCaseWenjian, dataProps..', dataProps);
    dataProps.wenjian = file;
    dataProps.summary = summary;
    dataProps.biaohao = biaohao;

    let res = await axios.post(apiUrl.updateCaseWenjian, dataProps);
    let data = res.data;
    console.log('data-->addCase-->, status: ', data );
    getList(caseId);

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addWenjianjia = async () => {
    console.log('添加文件夹', caseId);

    let dataProps = {};
    dataProps.caseId = caseId;
    dataProps.leibie = leibie;
    let file = wenjianListOne;
    let summary = hetongwenjianjihemingcheng;
    let wenjianjia = {
      list: [file],
      summary,
    }
    dataProps.wenjianjia = wenjianjia
    console.log('updateCase, dataProps..', dataProps);

    let res = await axios.post(apiUrl.updateCase, dataProps);
    let data = res.data;
    console.log('data-->addCase-->, status: ', data );
    getList(caseId);
  }

  return (
    <div>
      <List
        className='account-list'
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        header={<div>{leibie}</div>}
        dataSource={wenjianjia}
        renderItem={(item, index) => (
          <List.Item key={index} onClick={() => showModal(item.summary, index, item.list)}>
            <Badge count={item.list.length} showZero>
              <Card
                hoverable
                className='pdfCard'
                style={{ }}
                cover={<FilePdfTwoTone />}
              >
                <Meta description={item.summary} />
              </Card>
            </Badge>
          </List.Item>
        )}
      /> 
      <p>
        <span>文件夹名称</span>
        <Input
          placeholder='文件夹名称'
          size='large'
          onChange={ e => {
            setHetongwenjianjihemingcheng(e.target.value);
          }}
        />
      </p>
      <p>
        <span>文件夹内容</span>
      </p>

      {[0].map((value, index) => {
        return (
          <p>
            <Input.Group compact size='large'>
              <Input
                placeholder={`文件${index + 1}名`}
                onChange={ e => {
                  let wenjianListOneTmp = wenjianListOne;
                  wenjianListOneTmp.title = e.target.value;
                  console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                  setWenjianListOne(wenjianListOneTmp);
                }}
              />
              <Input
                placeholder={`文件${index + 1}URL`}
                size='large'
                onChange={ e => {
                  let wenjianListOneTmp = wenjianListOne;
                  wenjianListOneTmp.path = e.target.value;
                  console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                  setWenjianListOne(wenjianListOneTmp);
                }}
              />
            </Input.Group>
          </p>
        )
      })}
      <p><Button onClick={addWenjianjia}>添加一个文件夹</Button></p>
      <Modal title={summary} visible={isModalVisible} onOk={handleOk}
        onCancel={handleCancel}
        okText='添加文件'
        cancelText='返回'
      >
        {fileList.map((item, index) => {
          return (
            <p key={index}>
              <a href={item.path} target='_blank'>
                <FilePdfOutlined /> {item.title}
              </a>
            </p>
          );
        })}
      {[0].map((value, index) => {
        return (
          <p>
            <Input.Group compact size='large'>
              <Input
                placeholder={`文件${index + 1}名`}
                onChange={ e => {
                  let wenjianListOneTmp = wenjianListOne;
                  wenjianListOneTmp.title = e.target.value;
                  console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                  setWenjianListOne(wenjianListOneTmp);
                }}
              />
              <Input
                placeholder={`文件${index + 1}URL`}
                size='large'
                onChange={ e => {
                  let wenjianListOneTmp = wenjianListOne;
                  wenjianListOneTmp.path = e.target.value;
                  console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                  setWenjianListOne(wenjianListOneTmp);
                }}
              />
            </Input.Group>
          </p>
        )
      })}
      </Modal>
      <style global jsx>{`
        .ant-card.pdfCard .anticon {
          margin: 16px 0 12px;
          font-size: 48px;
        }
        .ant-card.pdfCard .ant-card-meta-title {
          text-align: center;
          font-weight: 400;
        }
        .account-list.ant-list-split .ant-list-header {
          border-bottom: 0px solid #f0f0f0;
        }
        .account-list .ant-badge {
          width: 100%;
        }
        .account-list {
          padding: 10px;
        }
      `}</style>
    </div>
  );
};

CaseList.propTypes = {
  data: PropTypes.array.isRequired,
  leibie: PropTypes.string.isRequired,
};

export default CaseList;
