import React, { useState } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Button,
  message,
} from 'antd';

import '../static/css/AddArticle.css';

function AddCase() {
  const [caseId,setCaseId] = useState('');   //项目Id
  const [image,setImage] = useState('') //动态图片

  const saveCase = async ()=>{
      if (!caseId) {
        message.error('项目ID不能为空');
        return false;
      } else if (!image) {
        message.error('图片不能为空');
        return false;
      }
      message.success('检验通过');

      let dataProps = {};
      dataProps.caseId = caseId;
      dataProps.imagesItem = {image};
      console.log('saveCase, dataProps..', dataProps);

      let res = await axios.post(apiUrl.updateQjtCase, dataProps);
      let data = res.data;
      console.log('data-->addCase-->, status: ', data );
  }
  const propsData = {
    keys: ['updateQjtCase'],
  };

  return (
    <GlobalLayout data={propsData}>
      <Row gutter={20}>
        <Col span={18}>
          <p>
          <span>项目ID: </span>
          <Input
            placeholder='项目ID'
            size='large'
            onChange= { e => {
              setCaseId(e.target.value);
            }}
          />
          </p>

          <p>
            <span>追加图片1:</span>
            <Input
              placeholder='图片URL'
              size='large'
              onChange={ e => {
                setImage(e.target.value);
              }}
            />
          </p>

          <p>
          <Button type='primary' size='large' onClick={saveCase}>保存</Button>
          </p>
        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #ccc' }}>
          <div>
            说明：项目ID为7位数，1000001等。
          </div>
        </Col>
      </Row>
    </GlobalLayout>
  )
}

export default AddCase;
