import React, { useState, useEffect } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  InputNumber,
  Button,
  message,
  List,
} from 'antd';

import '../static/css/ArticleList.css';

const { Option } = Select;


function UserList(props) {
  const [ list, setList ] = useState([]);

  useEffect( () => {
    getList();
  }, []);

  const getList = async () => {
    let dataProps = {};
    const res = await axios.get(apiUrl.getUserList, dataProps);
    let data = res.data.data;
    console.log('data-->getUserList-->, status: ', data );
    setList(data);
  };

  //删除文章
  const deleteUser = async phone => {
    console.log('----, phone: ', phone);
    const res = await axios.post(apiUrl.deleteUser, {phone: phone});
    let data = res.data.data;
    console.log('---->, deleteUser: ', data);
    getList();
  }

  const propsData = {
    keys: ['userList'],
  };

  return (
    <GlobalLayout data={propsData}>
      <div>
        <List
          header={
            <Row className='list-div'>
              <Col span={4}>
                手机号码
              </Col>
              <Col span={9}>
                公司名称
              </Col>
              <Col span={4}>
                联系人
              </Col>
              <Col span={4}>
                注册时间
              </Col>
              <Col span={3}>
                操作
              </Col>
            </Row>
          }
          bordered
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <Row className='list-div'>
                <Col span={4}>
                  {item.phone}
                </Col>
                <Col span={9}>
                  {item.name}
                </Col>
                <Col span={4}>
                 {item.contact}
                </Col>
                <Col span={4}>
                  {item.createTime.substring(0, 10)}
                </Col>
                <Col span={3}>
                  <Button onClick={() => deleteUser(item.phone)}>删除</Button>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
    </GlobalLayout>
  )
}

export default UserList;
