import React, { useState, useEffect } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  InputNumber,
  Button,
  message,
  List,
} from 'antd';

import '../static/css/ArticleList.css';

const { Option } = Select;


function ArticleList(props) {
  const [ list, setList ] = useState([]);

  useEffect( () => {
    getList();
  }, []);

  const getList = async () => {
    let dataProps = {};
    const res = await axios.get(apiUrl.getArticleList, dataProps);
    let data = res.data.data;
    console.log('data-->getArticleList-->, status: ', data );
    setList(data);
  };

  //删除文章
  const deleteArticle = async id => {
    console.log('----, id: ', id);
    const res = await axios.post(apiUrl.deleteArticle, {zhishitangId: id});
    let data = res.data.data;
    console.log('---->, deleteArticle: ', data);
    getList();
  }

  const propsData = {
    keys: ['articleList'],
  };

  return (
    <GlobalLayout data={propsData}>
      <div>
        <List
          header={
            <Row className='list-div'>
              <Col span={3}>
                ID
              </Col>
              <Col span={11}>
                标题
              </Col>
              <Col span={3}>
                类别
              </Col>
              <Col span={4}>
                发布时间
              </Col>
              <Col span={3}>
                操作
              </Col>
            </Row>
          }
          bordered
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <Row className='list-div'>
                <Col span={3}>
                  {item.zhishitangId}
                </Col>
                <Col span={11}>
                  {item.title}
                </Col>
                <Col span={3}>
                 {item.category}
                </Col>
                <Col span={4}>
                  {item.createTime.substring(0, 10)}
                </Col>
                <Col span={3}>
                  <Button onClick={() => deleteArticle(item.zhishitangId)}>删除</Button>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
    </GlobalLayout>
  )
}

export default ArticleList;
