import React, { useState } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  InputNumber,
  Button,
  message,
} from 'antd';

import '../static/css/AddArticle.css';

const { Option } = Select;


function AddArticle() {
  const [articleId,setArticleId] = useState(1100001)  // 文章的ID，如果是0说明是新增加，如果不是0，说明是修改
  const [articleTitle,setArticleTitle] = useState('')   //文章标题
  const [articleMdUrl , setArticleMdUrl] = useState('')  //markdown的URL
  const [shouyexianshi, setShouyexianshi] = useState('no');
  const defaultTypeInfo = [
    { id: 0, name: '设计sheji' },
    { id: 1, name: '施工shigong' },
    { id: 2, name: '办公园区bangong' },
    { id: 3, name: '展示商业fengshui' },
    { id: 4, name: '互动体验科技display' },
    { id: 5, name: '设计服务shejifuwu' },
    { id: 6, name: '施工解决方案shigongjiejuefangan' },
  ];
  const getCategory = typeId => {
    const categories = [
      "sheji",
      "shigong",
      "bangong",
      "fengshui",
      "display",
      "shejifuwu",
      "shigongjiejuefangan",
    ];
    return categories[typeId];
  };
  const [typeInfo ,setTypeInfo] = useState(defaultTypeInfo) // 文章类别信息
  const [selectedType,setSelectType] = useState(0) //选择的文章类别

  const selectTypeHandler =(value)=>{
    console.log('value: -->', value);
    setSelectType(value)
  }

  const defaultShouyexianshi = [
    {key: 'yes', value: '是',},
    {key: 'no', value: '否',},
  ];

  const selectShouyexianshi= value => {
    console.log('selectShouyexianshi, value: ', value);
    setShouyexianshi(value);
  }

  const saveArticle = async ()=>{
      if(selectedType === undefined || selectedType === null){
        message.error('必须选择文章类别');
        return false;
      }else if(!articleTitle){
        message.error('文章名称不能为空');
        return false;
      }
      message.success('检验通过');

      let dataProps = {};
      dataProps.zhishitangId = articleId;
      dataProps.title = articleTitle;
      dataProps.mdurl = articleMdUrl;
      dataProps.order = 10010;
      dataProps.category = getCategory(selectedType);
      dataProps.shouyexianshi = shouyexianshi;

      let res = await axios.post(apiUrl.addArticle, dataProps);
      let data = res.data;
      console.log('data-->addArticle-->, status: ', data );
  }
  const propsData = {
    keys: ['addArticle'],
  };

  return (
    <GlobalLayout data={propsData}>
      <Row gutter={20}>
        <Col span={18}>
          <p>
          <span>标题: </span>
          <Input
            placeholder='标题'
            size='large'
            onChange= { e => {
              setArticleTitle(e.target.value);
            }}
          />
          </p>
          <p>
          <span>URL: </span>
          <Input
            placeholder='Markdown格式文章URL'
            size='large'
            onChange={ e => {
              setArticleMdUrl(e.target.value);
            }}
          />
          </p>
          <p>
          <span>类型: </span>
          <Select
            defaultValue={ selectedType }
            size='large'
            style={{ width: 240 }}
            onChange={selectTypeHandler}
          >
            { typeInfo.map( (type, index) =>
              <Option key={index} value={type.id}>{type.name}</Option>
            )}
          </Select>
          </p>
          <p>
          <span>ID: </span>
          <InputNumber
            style={{ width: 240 }}
            min={1100001} max={9999999} defaultValue={1100001}
            onChange = { value => {
              setArticleId(value);
            }}
          />
          </p>

          <p>
          <span>是否首页显示: </span>
          <Select
            defaultValue={ shouyexianshi }
            size='large'
            style={{ width: 120 }}
            onChange={selectShouyexianshi}
          >
            { defaultShouyexianshi.map( (type, index) =>
              <Option key={index} value={type.key}>{type.value}</Option>
            )}
          </Select>
          </p>


          <p>
          <Button type='primary' size='large' onClick={saveArticle}>保存</Button>
          </p>
        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #ccc' }}>
          <div>
            说明：<br />
            设计--> 1100001~1199999<br />
            施工--> 1200001~1299999<br />
            办公园区--> 1300001~1399999<br />
            展示商业--> 1400001~1499999<br />
            互动体验科技--> 1500001~1599999<br />
            设计服务--> 1600001~1699999<br />
            施工解决方案--> 1700001~1799999<br />

          </div>
        </Col>
      </Row>
    </GlobalLayout>
  )
}

export default AddArticle;
