import React, { useState, useEffect } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  InputNumber,
  Button,
  message,
  List,
} from 'antd';

import '../static/css/ArticleList.css';

const { Option } = Select;


function UserList(props) {
  const [ list, setList ] = useState([]);

  useEffect( () => {
    getList();
  }, []);

  const getList = async () => {
    let dataProps = {};
    const res = await axios.get(apiUrl.getQjtCaseList, dataProps);
    let data = res.data.data;
    console.log('data-->getQjtCaseList-->, status: ', data );
    setList(data);
  };

  //删除案例
  const deleteCase = async caseId => {
    console.log('----, caseId: ', caseId);
    const res = await axios.post(apiUrl.deleteQjtCase, {caseId: caseId});
    let data = res.data.data;
    console.log('---->, deleteCase: ', data);
    getList();
  }

  const propsData = {
    keys: ['qjtCaseList'],
  };

  return (
    <GlobalLayout data={propsData}>
      <div>
        <List
          header={
            <Row className='list-div'>
              <Col span={4}>
                项目ID
              </Col>
              <Col span={7}>
                名称
              </Col>
              <Col span={3}>
                项目状态
              </Col>
              <Col span={3}>
                经度
              </Col>
              <Col span={3}>
                纬度
              </Col>
              <Col span={3}>
                操作
              </Col>
            </Row>
          }
          bordered
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <Row className='list-div'>
                <Col span={4}>
                  {item.caseId}
                </Col>
                <Col span={7}>
                  {item.name}
                </Col>
                <Col span={3}>
                  {item.caseState}
                </Col>
                <Col span={3}>
                  {item.jingdu}
                </Col>
                <Col span={3}>
                  {item.weidu}
                </Col>
                <Col span={3}>
                  <Button onClick={() => deleteCase(item.caseId)}>删除</Button>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
    </GlobalLayout>
  )
}

export default UserList;
