import React, { useState } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Button,
  message,
} from 'antd';

import '../static/css/AddArticle.css';

function AddUser() {
  const [phone,setPhone] = useState('');   //手机号码
  const [name,setName] = useState('') //名称
  const [contact,setContact] = useState('') //联系人
  const [address,setAddress] = useState('') //地址

  const saveUser = async ()=>{
      if(!phone){
        message.error('手机号码不能为空');
        return false;
      } else if (!name) {
        message.error('名称不能为空');
        return false;
      } else if (!contact) {
        message.error('联系人不能为空');
        return false;
      } else if (!address) {
        message.error('地址不能为空');
        return false;
      }
      message.success('检验通过');

      let dataProps = {};
      dataProps.phone = phone;
      dataProps.name = name;
      dataProps.contact = contact;
      dataProps.address = address;
      console.log('saveUser, dataProps..', dataProps);

      let res = await axios.post(apiUrl.addUser, dataProps);
      let data = res.data;
      console.log('data-->addArticle-->, status: ', data );
  }
  const propsData = {
    keys: ['addUser'],
  };

  return (
    <GlobalLayout data={propsData}>
      <Row gutter={20}>
        <Col span={18}>
          <p>
          <span>手机号码: </span>
          <Input
            placeholder='手机号码'
            size='large'
            onChange= { e => {
              setPhone(e.target.value);
            }}
          />
          </p>
          <p>
          <span>公司名称: </span>
          <Input
            placeholder='公司名称'
            size='large'
            onChange={ e => {
              setName(e.target.value);
            }}
          />
          </p>
          <p>
          <span>联系人: </span>
          <Input
            placeholder='联系人'
            size='large'
            onChange={ e => {
              setContact(e.target.value);
            }}
          />
          </p>
          <p>
          <span>地址: </span>
          <Input
            placeholder='地址'
            size='large'
            onChange={ e => {
              setAddress(e.target.value);
            }}
          />
          </p>
          <p>
          <Button type='primary' size='large' onClick={saveUser}>保存</Button>
          </p>
        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #ccc' }}>
          <div>
            说明：手机号码为13位，名称：个人写名字，公司写公司名称。
          </div>
        </Col>
      </Row>
    </GlobalLayout>
  )
}

export default AddUser;
