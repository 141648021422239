const urlPrefix = 'https://www.qzwy.com/api';

const composeUrl = action => urlPrefix + action;

const apiUrl = {
  addArticle: composeUrl('/knowledge/addArticle'),
  getArticleList: composeUrl('/knowledge/getArticleList'),
  deleteArticle: composeUrl('/knowledge/deleteArticle'),

  addUser: composeUrl('/user/addUser'),
  getUserList: composeUrl('/user/getUserList'),
  deleteUser: composeUrl('/user/deleteUser'),

  addProject: composeUrl('/project/addProject'),
  getProjectList: composeUrl('/project/getProjectList'),
  deleteProject: composeUrl('/project/deleteProject'),

  addCase: composeUrl('/case/addCase'),
  updateCase: composeUrl('/case/updateCase'),
  updateCaseWenjian: composeUrl('/case/updateCaseWenjian'),
  getCaseList: composeUrl('/case/getCaseList'),
  deleteCase: composeUrl('/case/deleteCase'),

  getProcessList: composeUrl('/process/getProcessList'),
  deleteProcess: composeUrl('/process/deleteProcess'),
  getProcessList: composeUrl('/process/getProcessList'),
  updateProcessWenjian: composeUrl('/process/updateProcessWenjian'),


  addQjtCase: composeUrl('/qjtCase/addCase'),
  updateQjtCase: composeUrl('/qjtCase/updateCase'),
  getQjtCaseList: composeUrl('/qjtCase/getCaseList'),
  deleteQjtCase: composeUrl('/qjtCase/deleteCase'),

  addQphLiuyan: composeUrl('/qphLiuyan/addQphLiuyan'),
  getQphLiuyanList: composeUrl('/qphLiuyan/getQphLiuyanList'),
  deleteQphLiuyan: composeUrl('/qphLiuyan/deleteQphLiuyan'),
};

export default apiUrl;
