import React, { useState } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import ProcessList from '../components/ProcessList';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Button,
  message,
  Select,
} from 'antd';

import '../static/css/AddArticle.css';

const { Option } = Select;

function UpdateProcess() {
  const defaultLeibie= [
    {key: 'shigong', value: '施工流程'},
    {key: 'cailiaozhenghe', value: '材料整合流程'},
  ];

  const [mobile,setMobile] = useState('');   //项目Id
  const [leibie, setLeibie] = useState(defaultLeibie[0].value);
  const [ list, setList ] = useState([]);
  const [ shigong, setShigong ] = useState([]);
  const [ cailiao, setCailiao ] = useState([]);
  const [title,setTitle] = useState('') //动态说明
  const [image,setImage] = useState('') //动态图片

  const selectLeibeiHandler = value => {
    console.log('selectLeibeiHandler, value: ', value);
    setLeibie(value);
  }

  const saveCase = async ()=>{
      if (!mobile) {
        message.error('项目ID不能为空');
        return false;
      } else if (!title || !image) {
        message.error('动态不能为空');
        return false;
      }
      message.success('检验通过');

      let dataProps = {};
      dataProps.mobile = mobile;
      dataProps.dongtai = {
        title,
        image,
      };
      console.log('saveCase, dataProps..', dataProps);

      let res = await axios.post(apiUrl.updateProcess, dataProps);
      let data = res.data;
      console.log('data-->addCase-->, status: ', data );
  }
  const propsData = {
    keys: ['updateProcess'],
  };

  return (
    <GlobalLayout data={propsData}>
      <Row gutter={20}>
        <Col span={18}>
          <p>
          <span>流程: </span>
          <Select
            defaultValue={ leibie }
            size='large'
            style={{ width: 120 }}
            onChange={selectLeibeiHandler}
          >
            { defaultLeibie.map( (item, index) =>
              <Option key={index} value={item.value}>{item.value}</Option>
            )}
          </Select>
          </p>
          <p>
          <span>手机号: </span>
          <Input
            placeholder='手机号'
            size='large'
            onChange= { e => {
              setMobile(e.target.value);
            }}
          />
          </p>

        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #ccc' }}>
          <div>
            说明：通过手机号查询。
          </div>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={18}>
          { mobile.length === 11
          ? <ProcessList leibie={leibie} mobile={mobile}/>
          : null
          }
        </Col>
      </Row>
    </GlobalLayout>
  )
}

export default UpdateProcess;
