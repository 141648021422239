import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import { Layout, Menu, Breadcrumb, message, Button } from 'antd';
import {
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  FileOutlined,
  ProjectOutlined,
} from '@ant-design/icons'; 
import './GlobalLayout.css'

import { useHistory } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


function GlobalLayout(props){

  const [collapsed,setCollapsed] = useState(false)
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('name') !== '1645687245648|1614151021') {
      localStorage.removeItem('name');
      history.push('/login');
      message.error('请登录!');
    }
  });

  const onCollapse = collapsed => {
    console.log('collapsed: ', collapsed);
    setCollapsed(collapsed)
  };

  const { data } = props;
  console.log('data: ', data);

  const handleLogout = () => {
    console.log('handleLogout...');
    localStorage.removeItem('name');
    history.push('/login');
  }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider  collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo" />
          <Menu theme="dark" defaultSelectedKeys={data.keys} defaultOpenKeys={['project', 'case', 'article', 'qipinhui', 'quanjingtu', 'user']} mode="inline">
            <Menu.Item key="dashboard">
              <a href='/dashboard'>
                <PieChartOutlined />
                <span>工作台</span>
              </a>
            </Menu.Item>
            <SubMenu
              key="project"
              title={
                <span>
                  <DesktopOutlined />
                  <span>案例管理</span>
                </span>
              }
            >
              <Menu.Item key="addProject"><a href='/addProject'>添加案例</a></Menu.Item>
              <Menu.Item key="projectList"><a href='/projectList'>案例列表</a></Menu.Item>
            </SubMenu>
            <SubMenu
              key="case"
              title={
                <span>
                  <ProjectOutlined />
                  <span>项目资料</span>
                </span>
              }
            >
              <Menu.Item key="addCase"><a href='/addCase'>添加项目</a></Menu.Item>
              <Menu.Item key="updateCase"><a href='/updateCase'>更新项目资料</a></Menu.Item>
              <Menu.Item key="caseList"><a href='/caseList'>项目列表</a></Menu.Item>
            </SubMenu>
            <SubMenu
              key="case"
              title={
                <span>
                  <ProjectOutlined />
                  <span>流程文件</span>
                </span>
              }
            >
              <Menu.Item key="updateProcess"><a href='/updateProcess'>更新流程文件</a></Menu.Item>
            </SubMenu>
            <SubMenu
              key="article"
              title={
                <span>
                  <FileOutlined />
                  <span>文章管理</span>
                </span>
              }
            >
              <Menu.Item key="addArticle"><a href='/addArticle'>添加文章</a></Menu.Item>
              <Menu.Item key="articleList"><a href='/articleList'>文章列表</a></Menu.Item>
            </SubMenu>
            <SubMenu
              key="qipinhui"
              title={
                <span>
                  <FileOutlined />
                  <span>企品汇管理</span>
                </span>
              }
            >
              <Menu.Item key="qphLiuyanList"><a href='/qphLiuyanList'>留言列表</a></Menu.Item>
            </SubMenu>
            <SubMenu
              key="quanjingtu"
              title={
                <span>
                  <ProjectOutlined />
                  <span>全景图管理</span>
                </span>
              }
            >
              <Menu.Item key="addQjtCase"><a href='/addQjtCase'>添加项目</a></Menu.Item>
              <Menu.Item key="updateQjtCase"><a href='/updateQjtCase'>更新项目</a></Menu.Item>
              <Menu.Item key="qjtCaseList"><a href='/qjtCaseList'>项目列表</a></Menu.Item>
            </SubMenu>
            <SubMenu
              key="user"
              title={
                <span>
                  <UserOutlined />
                  <span>用户管理</span>
                </span>
              }
            >
              <Menu.Item key="addUser"><a href='/addUser'>添加用户</a></Menu.Item>
              <Menu.Item key="userList"><a href='/userList'>用户列表</a></Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <div style={{float: "right", marginRight: "40px"}}>
              <Button onClick={handleLogout}>登出</Button>
            </div>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>后台管理</Breadcrumb.Item>
              <Breadcrumb.Item>工作台</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
              { props.children }
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>©企装无忧</Footer>
        </Layout>
      </Layout>
    )

}

GlobalLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GlobalLayout;
