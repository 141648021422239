import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AddArticle from './pages/AddArticle';
import ArticleList from './pages/ArticleList';

import AddUser from './pages/AddUser';
import UserList from './pages/UserList';

import AddCase from './pages/AddCase';
import UpdateCase from './pages/UpdateCase';
import CaseList from './pages/CaseList';

import UpdateProcess from './pages/UpdateProcess';

import AddProject from './pages/AddProject';
import ProjectList from './pages/ProjectList';

import QphLiuyanList from './pages/QphLiuyanList';

import AddQjtCase from './pages/AddQjtCase';
import UpdateQjtCase from './pages/UpdateQjtCase';
import QjtCaseList from './pages/QjtCaseList';

function App() {
  return (
    <Router>
      <Route path='/login' exact component={ Login } />
      <Route path='/dashboard' exact component={ Dashboard } />
      <Route path='/addArticle' exact component={ AddArticle } />
      <Route path='/articleList' exact component={ ArticleList } />
      <Route path='/addUser' exact component={ AddUser } />
      <Route path='/userList' exact component={ UserList } />
      <Route path='/addCase' exact component={ AddCase } />
      <Route path='/updateCase' exact component={ UpdateCase } />
      <Route path='/caseList' exact component={ CaseList } />

      <Route path='/updateProcess' exact component={ UpdateProcess } />

      <Route path='/addProject' exact component={ AddProject } />
      <Route path='/projectList' exact component={ ProjectList } />

      <Route path='/qphLiuyanList' exact component={ QphLiuyanList } />

      <Route path='/addQjtCase' exact component={ AddQjtCase } />
      <Route path='/updateQjtCase' exact component={ UpdateQjtCase } />
      <Route path='/qjtCaseList' exact component={ QjtCaseList } />
    </Router>
  )
}

export default App;
