import React, { useState } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import CaseList from '../components/CaseList';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Button,
  message,
  Select,
} from 'antd';

import '../static/css/AddArticle.css';

const { Option } = Select;

function AddCase() {
  const defaultLeibie= [
    {key: 'hetongwen', value: '合同',},
    {key: 'gongcheng', value: '工程文件',},
    {key: 'qianzheng', value: '签证、改签文件',},
    {key: 'baoxiu', value: '保修、维修文件',},
  ];

  const [caseId,setCaseId] = useState('');   //项目Id
  const [leibie, setLeibie] = useState(defaultLeibie[0].value);
  const [ list, setList ] = useState([{ziliao: []}]);
  const [title,setTitle] = useState('') //动态说明
  const [image,setImage] = useState('') //动态图片

  const selectLeibeiHandler = value => {
    console.log('selectLeibeiHandler, value: ', value);
    setLeibie(value);
  }

  const findCase = async () => {
    let dataProps = {
      params: {
        caseId
      },
    }
    console.log('findCase...', caseId, leibie, dataProps);
    const res = await axios.get(apiUrl.getCaseList, dataProps);
    let data = res.data.data;
    console.log('data-->findCase-->, status: ', data );
    setList(data);
  };

  const saveCase = async ()=>{
      if (!caseId) {
        message.error('项目ID不能为空');
        return false;
      } else if (!title || !image) {
        message.error('动态不能为空');
        return false;
      }
      message.success('检验通过');

      let dataProps = {};
      dataProps.caseId = caseId;
      dataProps.dongtai = {
        title,
        image,
      };
      console.log('saveCase, dataProps..', dataProps);

      let res = await axios.post(apiUrl.updateCase, dataProps);
      let data = res.data;
      console.log('data-->addCase-->, status: ', data );
  }
  const propsData = {
    keys: ['updateCase'],
  };

  return (
    <GlobalLayout data={propsData}>
      <Row gutter={20}>
        <Col span={18}>
          <p>
          <span>项目ID: </span>
          <Input
            placeholder='项目ID'
            size='large'
            onChange= { e => {
              setCaseId(e.target.value);
            }}
          />
          <span>资料类别: </span>
          <Select
            defaultValue={ leibie }
            size='large'
            style={{ width: 120 }}
            onChange={selectLeibeiHandler}
          >
            { defaultLeibie.map( (item, index) =>
              <Option key={index} value={item.value}>{item.value}</Option>
            )}
          </Select>
          <Button type='primary' size='large' onClick={findCase}>查询</Button>
          </p>

        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #ccc' }}>
          <div>
            说明：项目ID为7位数，1000001等。
          </div>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={18}>
          {list[0].ziliao.map( (ziliaoItem, index) => {
            if (defaultLeibie[index].value === leibie) {
              return (
                <CaseList key={index} data={ziliaoItem.wenjianjihe} leibie={ziliaoItem.leibie} caseId={caseId}/>
              );
            } else {
              return null;
            }
          })}
        </Col>
      </Row>
    </GlobalLayout>
  )
}

export default AddCase;
