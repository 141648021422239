import React, { useState } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  Button,
  message,
} from 'antd';

import '../static/css/AddArticle.css';

function AddCase() {
  const [caseId,setCaseId] = useState('');   //项目Id
  const [mobile,setMobile] = useState('');   //手机号码
  const [name,setName] = useState('') //名称
  const [ziliao, setZiliao] = useState([]); //资料

  const [hetongwenjianjihemingcheng, setHetongwenjianjihemingcheng] = useState('');
  const [wenjianListOne, setWenjianListOne] = useState({title: 'a1', path: 'b1'});

  const [gongchengwenjianjihemingcheng, setGongchengwenjianjihemingcheng] = useState('');
  const [wenjianListTwo, setWenjianListTwo] = useState({title: 'a1', path: 'b1'});

  const [qianzhengwenjianjihemingcheng, setQianzhengwenjianjihemingcheng] = useState('');
  const [wenjianListThree, setWenjianListThree] = useState({title: 'a1', path: 'b1'});

  const [baoxiuwenjianjihemingcheng, setBaoxiuwenjianjihemingcheng] = useState('');
  const [wenjianListFour, setWenjianListFour] = useState({title: 'a1', path: 'b1'});

  const saveCase = async ()=>{
      if (!caseId) {
        message.error('项目ID不能为空');
        return false;
      } else if(!mobile){
        message.error('手机号码不能为空');
        return false;
      } else if (!name) {
        message.error('名称不能为空');
        return false;
      }
      message.success('检验通过');

      let dataProps = {};
      dataProps.caseId = caseId;
      dataProps.mobile = mobile;
      dataProps.name = name;

      let ziliaoTmp = [];
      ziliaoTmp.push({
        leibie: "合同",
        wenjianjihe: [
          {
            summary: hetongwenjianjihemingcheng,
            list: wenjianListOne, 
          },
        ],
      });
      ziliaoTmp.push({
        leibie: "工程文件",
        wenjianjihe: [
          {
            summary: gongchengwenjianjihemingcheng,
            list: wenjianListTwo, 
          },
        ],
      });
      ziliaoTmp.push({
        leibie: "签证、改签文件",
        wenjianjihe: [
          {
            summary: qianzhengwenjianjihemingcheng,
            list: wenjianListThree, 
          },
        ],
      });
      ziliaoTmp.push({
        leibie: "保修、维修文件",
        wenjianjihe: [
          {
            summary: baoxiuwenjianjihemingcheng,
            list: wenjianListFour, 
          },
        ],
      });
      dataProps.ziliao = ziliaoTmp;

      console.log('saveCase, dataProps..', dataProps);

      let res = await axios.post(apiUrl.addCase, dataProps);
      let data = res.data;
      console.log('data-->addCase-->, status: ', data );
  }
  const propsData = {
    keys: ['addCase'],
  };

  return (
    <GlobalLayout data={propsData}>
      <Row gutter={20}>
        <Col span={18}>
          <p>
          <span>项目ID: </span>
          <Input
            placeholder='项目ID'
            size='large'
            onChange= { e => {
              setCaseId(e.target.value);
            }}
          />
          </p>
          <p>
          <span>用户手机号码: </span>
          <Input
            placeholder='手机号码'
            size='large'
            onChange= { e => {
              setMobile(e.target.value);
            }}
          />
          </p>
          <p>
          <span>项目名称: </span>
          <Input
            placeholder='项目名称'
            size='large'
            onChange={ e => {
              setName(e.target.value);
            }}
          />
          </p>

          <h4>合同</h4>
          <p>
            <span>文件夹名称</span>
            <Input
              placeholder='文件夹名称'
              size='large'
              onChange={ e => {
                setHetongwenjianjihemingcheng(e.target.value);
              }}
            />
          </p>
          <p>
            <span>文件夹内容</span>
          </p>

          {[0].map((value, index) => {
            return (
              <p>
                <Input.Group compact size='large'>
                  <Input
                    placeholder={`文件${index + 1}名`}
                    onChange={ e => {
                      let wenjianListOneTmp = wenjianListOne;
                      wenjianListOneTmp.title = e.target.value;
                      console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                      setWenjianListOne(wenjianListOneTmp);
                    }}
                  />
                  <Input
                    placeholder={`文件${index + 1}URL`}
                    size='large'
                    onChange={ e => {
                      let wenjianListOneTmp = wenjianListOne;
                      wenjianListOneTmp.path = e.target.value;
                      console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                      setWenjianListOne(wenjianListOneTmp);
                    }}
                  />
                </Input.Group>
              </p>
            )
          })}

          <h4>工程文件</h4>
          <p>
            <span>文件夹名称</span>
            <Input
              placeholder='文件夹名称'
              size='large'
              onChange={ e => {
                setGongchengwenjianjihemingcheng(e.target.value);
              }}
            />
          </p>
          <p>
            <span>文件夹内容</span>
          </p>

          {[0].map((value, index) => {
            return (
              <p>
                <Input.Group compact size='large'>
                  <Input
                    placeholder={`文件${index + 1}名`}
                    onChange={ e => {
                      let wenjianListOneTmp = wenjianListTwo;
                      wenjianListOneTmp.title = e.target.value;
                      console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                      setWenjianListTwo(wenjianListOneTmp);
                    }}
                  />
                  <Input
                    placeholder={`文件${index + 1}URL`}
                    size='large'
                    onChange={ e => {
                      let wenjianListOneTmp = wenjianListTwo;
                      wenjianListOneTmp.path = e.target.value;
                      console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                      setWenjianListTwo(wenjianListOneTmp);
                    }}
                  />
                </Input.Group>
              </p>
            )
          })}


          <h4>签证、改签文件</h4>
          <p>
            <span>文件夹名称</span>
            <Input
              placeholder='文件夹名称'
              size='large'
              onChange={ e => {
                setQianzhengwenjianjihemingcheng(e.target.value);
              }}
            />
          </p>
          <p>
            <span>文件夹内容</span>
          </p>

          {[0].map((value, index) => {
            return (
              <p>
                <Input.Group compact size='large'>
                  <Input
                    placeholder={`文件${index + 1}名`}
                    onChange={ e => {
                      let wenjianListOneTmp = wenjianListThree;
                      wenjianListOneTmp.title = e.target.value;
                      console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                      setWenjianListThree(wenjianListOneTmp);
                    }}
                  />
                  <Input
                    placeholder={`文件${index + 1}URL`}
                    size='large'
                    onChange={ e => {
                      let wenjianListOneTmp = wenjianListThree;
                      wenjianListOneTmp.path = e.target.value;
                      console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                      setWenjianListThree(wenjianListOneTmp);
                    }}
                  />
                </Input.Group>
              </p>
            )
          })}



          <h4>保修、维修文件</h4>
          <p>
            <span>文件夹名称</span>
            <Input
              placeholder='文件夹名称'
              size='large'
              onChange={ e => {
                setBaoxiuwenjianjihemingcheng(e.target.value);
              }}
            />
          </p>
          <p>
            <span>文件夹内容</span>
          </p>

          {[0].map((value, index) => {
            return (
              <p>
                <Input.Group compact size='large'>
                  <Input
                    placeholder={`文件${index + 1}名`}
                    onChange={ e => {
                      let wenjianListOneTmp = wenjianListFour
                      wenjianListOneTmp.title = e.target.value;
                      console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                      setWenjianListFour(wenjianListOneTmp);
                    }}
                  />
                  <Input
                    placeholder={`文件${index + 1}URL`}
                    size='large'
                    onChange={ e => {
                      let wenjianListOneTmp = wenjianListFour;
                      wenjianListOneTmp.path = e.target.value;
                      console.log('wenjianListOneTmp: ', wenjianListOneTmp);
                      setWenjianListFour(wenjianListOneTmp);
                    }}
                  />
                </Input.Group>
              </p>
            )
          })}

          <p>
          <Button type='primary' size='large' onClick={saveCase}>保存</Button>
          </p>
        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #ccc' }}>
          <div>
            说明：项目ID为7位数，1000001等。
          </div>
        </Col>
      </Row>
    </GlobalLayout>
  )
}

export default AddCase;
