import React, { useState, useEffect } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  InputNumber,
  Button,
  message,
  List,
  Avatar,
} from 'antd';

import '../static/css/ArticleList.css';

const { Option } = Select;

const mockdata = [
  {
    name: '邹文飞',
    mobile: '13902995088',
    liuyan: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
  },
  {
    name: '邹文飞',
    mobile: '13902995088',
    liuyan: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
  },
  {
    name: '邹文飞',
    mobile: '13902995088',
    liuyan: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
  },
  {
    name: '邹文飞',
    mobile: '13902995088',
    liuyan: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
  },
  {
    name: '邹文飞',
    mobile: '13902995088',
    liuyan: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
  },
  {
    name: '邹文飞',
    mobile: '13902995088',
    liuyan: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
  },
  {
    name: '邹文飞',
    mobile: '13902995088',
    liuyan: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
  },
];

function UserList(props) {
  const [ list, setList ] = useState([]);

  useEffect( () => {
    getList();
  }, []);

  const getList = async () => {
    let dataProps = {};
    const res = await axios.get(apiUrl.getQphLiuyanList, dataProps);
    let data = res.data.data;
    console.log('data-->getQphLiuyanList-->, status: ', data );
    setList(data);
  };

  //删除文章
  const deleteQphLiuyan = async _id => {
    console.log('----, _id: ', _id);
    const res = await axios.post(apiUrl.deleteQphLiuyan, {_id: _id});
    let data = res.data.data;
    console.log('---->, deleteQphLiuyan: ', data);
    getList();
  }

  const propsData = {
    keys: ['qphLiuyanList'],
  };

  return (
    <GlobalLayout data={propsData}>
      <div>
        <List
          itemLayout="horizontal"
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                title={<span>{item.name} {item.mobile}</span>}
                description={<div><p>意向类别：{item.yixiang}</p><p>留言内容：{item.liuyan}</p><p>{item.createTime}</p><p><Button onClick={() => deleteQphLiuyan(item._id)}>删除</Button></p></div>}
              />
            </List.Item>
          )}
        />
      </div>
    </GlobalLayout>
  )
}

export default UserList;
