import React, { useState, useEffect } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  InputNumber,
  Button,
  message,
  List,
} from 'antd';

import '../static/css/ArticleList.css';

const { Option } = Select;


function UserList(props) {
  const [ list, setList ] = useState([]);

  useEffect( () => {
    getList();
  }, []);

  const getList = async () => {
    let dataProps = {};
    const res = await axios.get(apiUrl.getProjectList, dataProps);
    let data = res.data.data;
    console.log('data-->getProjectList-->, status: ', data );
    setList(data);
  };

  //删除案例
  const deleteProject = async projectId => {
    console.log('----, projectId: ', projectId);
    const res = await axios.post(apiUrl.deleteProject, {projectId: projectId});
    let data = res.data.data;
    console.log('---->, deleteProject: ', data);
    getList();
  }

  const propsData = {
    keys: ['projectList'],
  };

  return (
    <GlobalLayout data={propsData}>
      <div>
        <List
          header={
            <Row className='list-div'>
              <Col span={4}>
                项目ID
              </Col>
              <Col span={9}>
                名称
              </Col>
              <Col span={4}>
                建筑面积
              </Col>
              <Col span={4}>
                类型
              </Col>
              <Col span={3}>
                操作
              </Col>
            </Row>
          }
          bordered
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <Row className='list-div'>
                <Col span={4}>
                  {item.projectId}
                </Col>
                <Col span={9}>
                  {item.projectDesc.name}
                </Col>
                <Col span={4}>
                 {item.projectDesc.area}
                </Col>
                <Col span={4}>
                  {item.category}
                </Col>
                <Col span={3}>
                  <Button onClick={() => deleteProject(item.projectId)}>删除</Button>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
    </GlobalLayout>
  )
}

export default UserList;
