import React, { useState } from 'react';
import 'antd/dist/antd.css';
import '../static/css/Login.css';
import { Card, Input, Button, Spin, message } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { adminLogin } from '../api/admin';

function Login() {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const checkLogin = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000)
    const { success, data } = await adminLogin({userName, password});
    if (success) {
      localStorage.setItem('name', '1645687245648|1614151021');
      history.push('/dashboard');
      message.success('登录成功！');
    } else {
      message.error('账号或者密码错误！');
    }
  };

  return (
    <div className="login-div">

        <Spin tip="Loading..." spinning={isLoading}>
            <Card title="企装无忧管理后台" bordered={true} style={{ width: 400 }} >
                <Input
                    id="userName"
                    size="large"
                    placeholder="账号"
                    prefix={<UserOutlined style={{color:'rgba(0,0,0,.25)'}} />}
                    onChange={(e)=>{setUserName(e.target.value)}}
                /> 
                <br/><br/>
                <Input.Password
                    id="password"
                    size="large"
                    placeholder="密码"
                    prefix={<KeyOutlined style={{color:'rgba(0,0,0,.25)'}} />}
                    onChange={(e)=>{setPassword(e.target.value)}}
                />     
                <br/><br/>
                <Button type="primary" size="large" block onClick={checkLogin} >登录</Button>
            </Card>
        </Spin>
    </div>
  );
}

export default Login;
