import React, { useState } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  Button,
  message,
} from 'antd';

import '../static/css/AddArticle.css';

const { Option } = Select;

function AddCase() {
  const [caseId,setCaseId] = useState('');   //项目Id
  const [name,setName] = useState('') //名称
  const [jingdu, setJingdu] = useState(0); // 经度
  const [weidu, setWeidu] = useState(0); // 纬度
  const [caseState, setCaseState] = useState('开工大吉');
  const [images, setImages] = useState([]) //动态图片

  const defaultCaseStateInfo= [
    {key: 'kaigongdaji', value: '开工大吉',},
    {key: 'zhengzaishigongzhong', value: '正在施工中',},
    {key: 'yishunliwangong', value: '已顺利完工',},
  ];

  const saveCase = async ()=>{
      if (!caseId) {
        message.error('项目ID不能为空');
        return false;
      } else if(!name){
        message.error('项目名称不能为空');
        return false;
      } else if (!jingdu) {
        message.error('经度不能为空');
        return false;
      } else if (!weidu) {
        message.error('纬度不能为空');
        return false;
      } else if (!caseState) {
        message.error('是否完工不能为空');
        return false;
      } else if (!images) {
        message.error('图片不能为空');
        return false;
      }
      message.success('检验通过');

      let dataProps = {};
      dataProps.caseId = caseId;
      dataProps.name = name;
      dataProps.jingdu = jingdu;
      dataProps.weidu = weidu;
      dataProps.caseState = caseState;
      dataProps.images = images;
      console.log('saveQjtCase, dataProps..', dataProps);

      let res = await axios.post(apiUrl.addQjtCase, dataProps);
      let data = res.data;
      console.log('data-->addQjtCase-->, status: ', data );
  }
  const propsData = {
    keys: ['addQjtCase'],
  };

  const selectCaseStateHandler = value => {
    console.log('selectCaseStateHandler, value: ', value);
    setCaseState(value);
  }

  return (
    <GlobalLayout data={propsData}>
      <Row gutter={20}>
        <Col span={18}>
          <p>
          <span>项目ID: </span>
          <Input
            placeholder='项目ID'
            size='large'
            onChange= { e => {
              setCaseId(e.target.value);
            }}
          />
          </p>
          <p>
          <span>项目名称: </span>
          <Input
            placeholder='项目名称'
            size='large'
            onChange={ e => {
              setName(e.target.value);
            }}
          />
          </p>
          <p>
          <span>工程状态: </span>
          <Select
            defaultValue={ caseState }
            size='large'
            style={{ width: 120 }}
            onChange={selectCaseStateHandler}
          >
            { defaultCaseStateInfo.map( (state, index) =>
              <Option key={index} value={state.value}>{state.value}</Option>
            )}
          </Select>
          </p>

          <p>
            <span>经度</span>
            <Input
              placeholder='经度'
              size='large'
              onChange={ e => {
                setJingdu(e.target.value);
              }}
            />
          </p>

          <p>
            <span>纬度</span>
            <Input
              placeholder='纬度'
              size='large'
              onChange={ e => {
                setWeidu(e.target.value);
              }}
            />
          </p>

          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, index) =>
            <p>
              <span>{`图片${index + 1}: `}</span>
              <Input
                placeholder='图片URL'
                size='large'
                onChange={ e => {
                  let tempImages = images;
                  tempImages[index] = {image: e.target.value};
                  console.log('tempImages: ', tempImages);
                  setImages(tempImages);
                }}
              />
            </p>
          )}

          <p>
          <Button type='primary' size='large' onClick={saveCase}>保存</Button>
          </p>
        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #ccc' }}>
          <div>
            说明：项目ID为7位数，1000001等。
          </div>
        </Col>
      </Row>
    </GlobalLayout>
  )
}

export default AddCase;
