import React, { useState } from 'react';
import GlobalLayout from '../components/GlobalLayout';
import axios from 'axios';
import apiUrl from '../config/apiUrl';

import {
  Row, Col,
  Input,
  Select,
  Button,
  message,
} from 'antd';

import '../static/css/AddArticle.css';

const { Option } = Select;


function AddProject() {
  const [projectId,setProjectId] = useState('100001');   //项目ID
  const [name,setName] = useState('') //名称
  const [category, setCategory] = useState('office');
  const [price, setPrice] = useState('');
  const [area, setArea] = useState('');
  const [service, setService] = useState('');
  const [summary, setSummary] = useState('');
//  const [shouyexianshi, setShouyexianshi] = useState('no');
//  const [shouyefengmian, setShouyefengmian] = useState('');
  const [fengmian, setFengmian] = useState('');
  const [images, setImages] = useState([]);

  const defaultCategoryInfo = [
    {key: 'office', value: '办公园区',},
    {key: 'display', value: '展示商业',},
  ];

  const selectTypeHandler = value => {
    console.log('selectTypeHandler, value: ', value);
    setCategory(value);
  }

{/*
  const defaultShouyexianshi = [
    {key: 'yes', value: '是',},
    {key: 'no', value: '否',},
  ];

  const selectShouyexianshi= value => {
    console.log('selectShouyexianshi, value: ', value);
    setShouyexianshi(value);
  }
*/}

  const saveProject = async ()=>{
      if(!projectId){
        message.error('项目ID不能为空');
        return false;
      } else if (!name) {
        message.error('名称不能为空');
        return false;
      } else if (!category) {
        message.error('分类不能为空');
        return false;
      }
      message.success('检验通过');

      let dataProps = {};
      dataProps.projectId = projectId;
      dataProps.category = category;

      let projectDesc = {};
      projectDesc.name = name;
      projectDesc.area = area + '㎡';
      projectDesc.price = price + '万';
      projectDesc.service = service;
      projectDesc.summary = summary;

      dataProps.projectDesc = projectDesc;
      dataProps.projectImages = images;

//      dataProps.shouyexianshi = shouyexianshi;
//      dataProps.shouyefengmian = shouyefengmian;
      dataProps.fengmian = fengmian;

      console.log('saveProject, dataProps..', dataProps);

      let res = await axios.post(apiUrl.addProject, dataProps);
      let data = res.data;
      console.log('data-->addProject-->, status: ', data );
  }
  const propsData = {
    keys: ['addProject'],
  };

  return (
    <GlobalLayout data={propsData}>
      <Row gutter={20}>
        <Col span={18}>
          <p>
          <span>项目ID: </span>
          <Input
            placeholder='项目ID'
            size='large'
            onChange= { e => {
              setProjectId(e.target.value);
            }}
          />
          </p>
          <p>
          <span>类别: </span>
          <Select
            defaultValue={ category }
            size='large'
            style={{ width: 120 }}
            onChange={selectTypeHandler}
          >
            { defaultCategoryInfo.map( (type, index) =>
              <Option key={index} value={type.key}>{type.value}</Option>
            )}
          </Select>
          </p>
          <p>
          <span>项目名称: </span>
          <Input
            placeholder='项目名称'
            size='large'
            onChange={ e => {
              setName(e.target.value);
            }}
          />
          </p>
          <p>
          <span>建筑面积: </span>
          <Input
            placeholder='建筑面积'
            size='large'
            addonAfter={'㎡'}
            onChange={ e => {
              setArea(e.target.value);
            }}
          />
          </p>
          <p>
          <span>工程造价: </span>
          <Input
            placeholder='工程造价'
            size='large'
            addonAfter={'万'}
            onChange={ e => {
              setPrice(e.target.value);
            }}
          />
          </p>
          <p>
          <span>服务项目: </span>
          <Input
            placeholder='服务项目'
            size='large'
            onChange={ e => {
              setService(e.target.value);
            }}
          />
          </p>
          <p>
          <span>项目描述: </span>
          <Input.TextArea
            rows={6}
            placeholder='项目描述'
            size='large'
            onChange={ e => {
              setSummary(e.target.value);
            }}
          />
          </p>

{/*
          <p>
          <span>是否首页显示封面: </span>
          <Select
            defaultValue={ shouyexianshi }
            size='large'
            style={{ width: 120 }}
            onChange={selectShouyexianshi}
          >
            { defaultShouyexianshi.map( (type, index) =>
              <Option key={index} value={type.key}>{type.value}</Option>
            )}
          </Select>
          </p>

          <p>
          <span>首页封面: </span>
          <Input
            disabled={shouyexianshi === 'yes' ? false : true}
            placeholder='图片URL'
            size='large'
            onChange={ e => {
              setShouyefengmian(e.target.value);
            }}
          />
          </p>
*/}

          <p>
          <span>列表封面: </span>
          <Input
            placeholder='图片URL'
            size='large'
            onChange={ e => {
              setFengmian(e.target.value);
            }}
          />
          </p>

          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, index) =>
            <p>
              <span>{`图片${index + 1}: `}</span>
              <Input
                placeholder='图片URL'
                size='large'
                onChange={ e => {
                  let tempImages = images;
                  tempImages[index] = {key: index, image: e.target.value};
                  console.log('tempImages: ', tempImages);
                  setImages(tempImages);
                }}
              />
            </p>
          )}

          <p>
          <Button type='primary' size='large' onClick={saveProject}>保存</Button>
          </p>
        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #ccc' }}>
          <div>
            <p>
              说明：所有 URL 的前缀：https://public.qzwy.com
            </p>
            <p>
              举例：https://public.qzwy.com 替换 OSS 浏览器中的oss://qzwy/static/images/office/banner/banner-bg1.jpg中的oss://qzwy得到：https://public.qzwy.com/static/images/office/banner/banner-bg1.jpg，即是我们所需要的 URL 。
            </p>
            <p>
              /static/images/projects/office<br />
              /static/images/projects/display<br />
              这两个目录存放了图片资源
            </p>
          </div>
        </Col>
      </Row>
    </GlobalLayout>
  )
}

export default AddProject;
